import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    props: { loggedIn: false, loggedOut: false },
    component: Index,
    meta: {
      title: 'Vlaams-Brabant'
    }
  },
  {
    path: '/loggout',
    name: 'index-loggout',
    props: { loggedIn: false, loggedOut: false, loggedOutOtherLocation: false },
    component: Index,
    meta: {
      title: 'Vlaams-Brabant'
    }
  },
  {
    path: '/catalogus/artikel/:itemID',
    alias: '/artikel/:itemID',
    name: 'item',
    component: () => import('../views/Item/Index.vue')
  },
  {
    path: '/catalogus/:categoryID',
    name: 'catalogus',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/catalogus/:categoryID/:subcategoryID',
    name: 'catalogus',
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/catalogus/:categoryID/:subcategoryID/artikel/:itemID',
    name: 'catalogus',
    component: () => import('../views/Item/Index.vue')
  },

  {
    path: '/zoeken/:searchTerm',
    name: 'search',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Shop.vue'),
    meta: {
      title: 'Vlaams-Brabant | Zoeken'
    }
  },
  {
    path: '/zoeken/:searchTerm/artikel/:itemID',
    name: 'catalogus',
    component: () => import('../views/Item/Index.vue')
  },
  // {
  //   path: '/artikel/:itemDescription/:itemID',
  //   name: 'item',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import('../views/Item/Index.vue')
  // },
  {
    path: '/pakketten',
    name: 'packages',

    component: () => import('../views/Shop.vue')
  },
  {
    path: '/pakketten/artikel/:itemID',
    name: 'package',

    component: () => import('../views/Item/Index.vue')
  },
  {
    path: '/inloggen',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/Index.vue'),
    meta: {
      title: 'Vlaams-Brabant | Inloggen'
    }
  },
  {
    path: '/profiel-keuze',
    name: 'profileSelector',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ProfileSelector.vue'),
    meta: {
      title: 'Vlaams-Brabant | Profiel keuze'
    }
  },
  {
    path: '/registreren',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/Index.vue'),
    meta: {
      title: 'Vlaams-Brabant | Registreren'
    }
  },

  {
    path: '/nieuwe-sub-gebruiker',
    name: 'newSub',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/NewSub.vue'),
    meta: {
      title: 'Vlaams-Brabant | Nieuwe sub gebruiker'
    }
  },
  {
    path: '/sub-gebruiker-aanpassen',
    name: 'editSub',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/EditSub.vue'),
    meta: {
      title: 'Vlaams-Brabant | Sub gebruiker aanpassen'
    }
  },
  {
    path: '/je-account',
    name: 'account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/Index.vue'),
    meta: {
      title: 'Vlaams-Brabant | Account'
    }
  },

  {
    path: '/reservering/:reservationID',
    name: 'reservation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/Reservation.vue')
  },
  {
    path: '/winkelmandje',
    name: 'cart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Cart/Index.vue'),
    meta: {
      title: 'Vlaams-Brabant | Winkelmandje'
    }
  },
  {
    path: '/bevestiging',
    name: 'confirmation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Cart/Confirmation.vue'),
    meta: {
      title: 'Vlaams-Brabant | Confirmatie'
    }
  },
  {
    path: '/bevestiging-reservering',
    name: 'reservation-confirmation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Cart/ReservationConfirmation.vue')
  },
  {
    path: '/succesvol',
    name: 'successfull',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Cart/Succesfull.vue'),
    meta: {
      title: 'Vlaams-Brabant | Succesvol'
    }
  },
  {
    path: '/wachtwoord-herstellen',
    name: 'forgotPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ForgotPassword.vue'),
    meta: {
      title: 'Vlaams-Brabant | Wachtwoord hrestellen'
    }
  },
  {
    path: '/account-aangemaakt',
    name: 'accountCreated',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountCreated.vue'),
    meta: {
      title: 'Vlaams-Brabant | Account aangemaakt'
    }
  },
  {
    path: '/account-aangemaakt/:code',
    name: 'accountCreated',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountCreated.vue'),
    meta: {
      title: 'Vlaams-Brabant | Account aangemaakt'
    }
  },
  {
    path: '/openings-days-overview-workload-insight',
    name: 'openings-days-overview-workload-insight',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/OpeningsDays/Index.vue'),
    meta: {
      title: 'Vlaams-Brabant | Werkdruk overzicht'
    }
  },
  {
    path: '/account-aangemaakt-bestaande-gebruiker',
    name: 'accountCreatedExistingAccount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/Register/AccountCreatedExistingAccount.vue'),
    meta: {
      title: 'Vlaams-Brabant | Account aangemaakt'
    }
  },
  {
    path: '/niet-toegestaan',
    name: '401',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/401/Index.vue'),
    meta: {
      title: 'Vlaams-Brabant | Niet toegestaan'
    }
  },
  {
    path: '/niet-gevonden',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/404/Index.vue'),
    meta: {
      title: 'Vlaams-Brabant | Niet gevonden'
    }
  },

  {
    path: '/account-email-verificatie',
    name: 'accountEmailSend',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountEmailSend.vue'),
    meta: {
      title: 'Vlaams-Brabant | Account email verificatie'
    }
  },
  {
    path: '/nieuw-wachtwoord/:code',
    name: 'newPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ResetPassword.vue'),
    meta: {
      title: 'Vlaams-Brabant | Nieuw wachtwoord'
    }
  },
  {
    path: '/nieuw-wachtwoord/',
    name: 'newPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ResetPassword.vue'),
    meta: {
      title: 'Vlaams-Brabant | Nieuw wachtwoord'
    }
  },
  { path: '*', component: () => import('../views/404/Index.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
