export function setTitleFromUrl(url) {
  // if url contains /artikel/ then set the title to everything after /artikel/
  if (url.indexOf('/artikel/') > -1) {
    let title = url.split('/artikel/')[1]
    // capitalize first letter of title
    title = title.charAt(0).toUpperCase() + title.slice(1)
    //strip -
    title = title.replace(/-/g, ' ')

    document.title = `${title} - Vlaams-Brabant Uitleendienst`
  }
}
