<template>
  <ul class="vacatures-deputatie">
    <li
      v-for="subCategory in categories.slice().splice(0, 5)"
      :key="subCategory.CategoryID"
    >
      <router-link
        :to="{
          path: `/catalogus/${parentCategorySlug}/${subCategory.Slug}`
        }"
        >{{ subCategory.Description }}</router-link
      >
    </li>
  </ul>
</template>
<script>
export default {
  name: 'ChildCategories',
  props: {
    categories: {
      type: Array,
      default: null
    },
    parentCategorySlug: {
      type: String,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
ul {
  list-style: none;
  padding-left: 23px !important;
  li:before {
    list-style: none;
    content: '>' !important;

    margin-left: -20px;
    margin-right: 10px;
  }
  li {
    line-height: 18px !important;
    font-size: 18px !important;
    font-family: 'Roboto';
    padding-bottom: 25px;
  }
}
</style>
