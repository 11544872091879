<template>
  <router-link to="/">
    <img
      style="
    width: 179px;
    height: 243px;
"
      alt="Logo Provincie Vlaams-Brabant"
      src="/img/Logo.svg"
    />
  </router-link>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
img {
  height: 243px;
  margin-top: 0px;
}
</style>
