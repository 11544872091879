<template>
  <b-container class="page-container">
    <Breadcrumbs v-if="parentCategoryID" :currentpage="parentCategoryID" />
    <b-alert v-if="loggedIn && !loggedOut" show variant="info">
      <font-awesome-icon icon="exclamation-circle" />Je bent nu ingelogd.
    </b-alert>
    <b-alert v-if="loggedOut" show variant="info">
      <font-awesome-icon icon="exclamation-circle" />Je bent nu uitgelogd.
    </b-alert>
    <b-alert v-if="loggedOutOtherLocation" show variant="info">
      <font-awesome-icon icon="exclamation-circle" />Je bent uitgelogd omdat je
      ingelogd bent op een andere locatie of omdat je sessie verlopen is.
    </b-alert>
    <b-row>
      <b-col
        v-for="category in categories"
        :key="category.CategoryID"
        class="mb-4"
        xs="12"
        md="6"
        lg="4"
        xl="3"
      >
        <b-card
          v-if="!category.ChildCategories || parentCategoryID"
          :hide-footer="true"
          :header="category.Description.toUpperCase()"
          class="category"
        >
          <template v-slot:header>
            <router-link
              :to="{
                path: `/catalogus/${parentCategoryID}/${category.Slug}`
              }"
              >{{ category.Description.toUpperCase() }}</router-link
            >
          </template>
          <b-card-text>
            <div class="mask"></div>

            <ChildItems
              v-if="parentCategoryID"
              :parent-category-slug="parentCategoryID"
              :category-slug="category.Slug"
              :category-i-d="category.CategoryID"
            />
          </b-card-text>
          <template v-slot:footer>
            <router-link
              :to="{
                path: `/catalogus/${parentCategoryID}/${category.Slug}`
              }"
              >Bekijk alle {{ category.Description }} artikelen<span
                class="arrow-link"
                ><font-awesome-icon icon="chevron-right"/></span
            ></router-link>
          </template>
        </b-card>
        <b-card
          v-if="category.ChildCategories"
          class="category"
          :header="category.Description.toUpperCase()"
          header-tag="header"
        >
          <template v-slot:header>
            <router-link
              v-if="category.CategoryID && category.CategoryID === 'Pakketten'"
              :to="{
                path: `/pakketten`
              }"
              >{{ category.Description.toUpperCase() }}</router-link
            >
            <router-link
              v-else
              :to="{
                path: `/catalogus/${category.Slug}`
              }"
              >{{ category.Description.toUpperCase() }}</router-link
            >
          </template>
          <b-card-text>
            <div class="mask"></div>

            <ChildCategories
              v-if="
                category.ChildCategories != null &&
                  category.Description != 'Pakketten'
              "
              :categories="category.ChildCategories"
              :parent-category-slug="category.Slug"
            />

            <ChildItems
              v-else
              :packages="true"
              :category-i-d="category.CategoryID"
              :package-items="packages"
            />
          </b-card-text>

          <template
            v-if="
              category.ChildCategories != null &&
                category.Description != 'Pakketten'
            "
            v-slot:footer
          >
            <router-link
              :to="{
                name: 'catalogus',
                params: {
                  categoryDescription: category.Description,
                  categoryID: category.Slug
                }
              }"
              >Bekijk alle
              {{ category.Description.toLowerCase() }} categorieën<span
                class="arrow-link"
                ><font-awesome-icon icon="chevron-right"/></span
            ></router-link>
          </template>
          <template v-else v-slot:footer>
            <router-link
              v-if="category.Description != 'Pakketten'"
              :to="{
                name: 'items',
                params: {
                  subcategoryID: category.Slug,
                  categoryID: category.ParentCategoryID
                }
              }"
              >Bekijk alle {{ category.Description.toLowerCase()
              }}<span class="arrow-link"
                ><font-awesome-icon icon="chevron-right"/></span
            ></router-link>
            <router-link
              v-if="category.Description == 'Pakketten'"
              :to="{
                name: 'packages'
              }"
              >Bekijk alle {{ category.Description.toLowerCase()
              }}<span class="arrow-link"
                ><font-awesome-icon icon="chevron-right"/></span
            ></router-link>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
import ChildCategories from '@/views/Category/ChildCategories'
import ChildItems from '@/views/Category/ChildItems'
import { getCategories, getCategory } from '@/services/CategoryService'
import { getPackages } from '@/services/ItemService'

export default {
  name: 'Home',
  components: {
    ChildCategories,
    ChildItems,
    Breadcrumbs
  },
  data() {
    return {
      seoData: {},
      categories: [],
      dataRoute: null,
      gettingData: null,
      packages: [],
      category: {},
      categoryDescription: undefined
    }
  },
  metaInfo: function() {
    return {
      title: `${this.seoData.Description ?? 'Home'}`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst',
      meta: [
        {
          name: `description`,
          content: `${this.metaDescription}`,
          vmid: 'og:meta-description'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${window.location.href}`
        }
      ]
    }
  },
  computed: {
    metaDescription() {
      if (this.seoData.Description) {
        return `Categorie ${this.seoData.Description}`
      } else {
        return 'Officiële website van de provincie Vlaams-Brabant Uitleendienst.'
      }
    },
    loggedIn: function() {
      return this.$route.params.loggedIn
    },
    loggedOut: function() {
      return this.$route.query.loggedOut
    },
    loggedOutOtherLocation: function() {
      return this.$route.query.loggedOutOtherLocation
    },
    parentCategoryID: function() {
      return this.$route.params.categoryID
    },
    parentCategoryDescription: function() {
      return this.$route.params.categoryDescription
    }
  },
  async created() {
    await this.getCategorySEOData()
    await this.getData()
  },

  methods: {
    updateCategories: async function(event) {
      const result = await getCategory({
        categoryID: event.target.value
      })

      if (
        result.data.Message &&
        result.data.Message === 'Invalid authetication Token.'
      ) {
        this.$router.push({ name: 'index-loggout', query: { loggedOut: true } })
      }

      this.categories = result.ChildCategories
    },
    async getCategorySEOData() {
      if (this.$route.params.categoryID != null) {
        let categoryDescription =
          this.$route.params.subcategoryID ?? this.$route.params.categoryID
        if (categoryDescription.includes('-')) {
          categoryDescription = categoryDescription.replace('-', ' ')
        }
        // set first letter to uppercase
        categoryDescription =
          categoryDescription.charAt(0).toUpperCase() +
          categoryDescription.slice(1)
        this.seoData.Description = categoryDescription
        const result = await getCategory({
          categoryID:
            this.$route.params.subcategoryID ?? this.$route.params.categoryID,
          slug: true
        })
        this.seoData = result
      }
    },
    getData: async function() {
      if (this.dataRoute != this.$route.fullPath) {
        this.dataRoute = this.$route.fullPath

        let result

        if (this.$route.params.categoryID != null) {
          result = await getCategory({
            categoryID: this.$route.params.categoryID,
            slug: true
          })

          if (result && result.Message === 'The request is invalid.') {
            this.$router.push({ name: '404' })
          }

          if (
            result.data &&
            result.data.Message === 'Invalid authetication Token.'
          ) {
            await this.$store.commit('unsetUser')
            await this.$store.commit('unsetCart')

            this.$router.push({
              name: 'index-loggout',
              query: { loggedOut: true }
            })
          }

          this.category = result
          this.title = this.category.Description
          this.categories = new Array().concat(result.ChildCategories)
        } else {
          result = await getCategories({ slug: true })

          if (
            result.data &&
            result.data.Message === 'The request is invalid.'
          ) {
            this.$router.push({ name: '404' })
          }

          if (
            result.data &&
            result.data.Message === 'Invalid authetication Token.'
          ) {
            await this.$store.commit('unsetUser')
            await this.$store.commit('unsetCart')

            this.$router.push({
              name: 'index-loggout',
              query: { loggedOutOtherLocation: true }
            })
          }

          this.categories = result.data
          let packageObject = {
            CustomerGroupID: 6,
            CustomerGroupDescription: 'Gemeente',
            CategoryID: 'Pakketten',
            Description: 'Pakketten',
            ParentCategoryID: null,
            Ranking: 1,
            ItemCount: 695,
            Notes:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mauris neque, vestibulum vitae neque eget, rhoncus sodales lacus. Sed rhoncus pulvinar elit, a sagittis magna tincidunt eu.',
            ChildCategories: [
              {
                CustomerGroupID: 6,
                CustomerGroupDescription: 'Gemeente',
                CategoryID: 110,
                Description: 'Luidsprekers',
                ParentCategoryID: 1,
                Ranking: 110,
                ItemCount: 26,
                Notes: null
              }
            ]
          }
          this.packages = await getPackages({ allItemData: false })

          this.categories.push(packageObject)
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.arrow-link {
  text-decoration: none;
  font-size: 9px;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 6px;
  padding-right: 6px;
}
.card-footer {
  border-top: 1px grey;
  text-align: left !important;
  padding-bottom: 20px;
}
.card {
  background-color: white;
  border-radius: 4px;
  border: none;
  height: 100%;
  margin-bottom: 20px;
  ul {
    padding-top: 10px;
    padding-left: 0px;
    a {
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 26px;
      color: #015ea5;
    }
  }
}
.category {
  .card-header {
    color: #070808;
    a {
      color: #070808;
      text-decoration: none;
      font-family: 'DecimaPro' !important;
    }
    a:hover {
      color: #013359;
    }
    div {
      font-family: 'DecimaPro' !important;
    }
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    min-height: 72px;
    background: none;
    border: none;
    height: auto !important;
  }
  .card-body {
    padding-top: 0px;
  }
  .card-footer {
    background-color: white;
    border-top: 1px solid rgba(236, 236, 236, 0.7);
    min-height: 70px;
    max-height: 70px;
    line-height: 35px !important;
    text-align: center;
    color: #015ea5;
    font-family: Decima;
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    a {
      vertical-align: middle;
      line-height: normal;
      display: inline-block;
    }
  }
}

.mask {
  height: 2px;
  width: 80px;
  transform: scaleY(-1);
  background-color: #070808;
  margin-bottom: 20px;
}

.vacatures-deputatie {
  color: #015ea5;
  font-family: Roboto;
  font-size: 18px;
  line-height: 41px;
  li {
    list-style-type: none;
  }
  li:before {
    content: '>';
  }
}
</style>
