const { axiosCreate } = require('./Api')
const dayjs = require('dayjs')
import store from '../state/store'

async function getCart() {
  const result = await axiosCreate.get(`cart/${store.getters.contactID}`, {
    headers: { authorization: store.getters.token }
  })
  if (result.data.Message === 'Invalid authetication Token.') {
    return 'loggedOut'
  }

  if (result.data) {
    store.commit('setCart', {
      cart: result.data
    })
  }

  store.commit('setDateTimeBusinessStart', {
    date: dayjs(result.data.DateTimeBusinessStart, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    )
  })

  store.commit('setDateTimeBusinessEnd', {
    date: dayjs(result.data.DateTimeBusinessEnd, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    )
  })

  let cartItems = []

  if (result.data.CartItems && result.data.CartItems.Collection) {
    result.data.CartItems.Collection.forEach(cartItem => {
      cartItems.push({
        item: cartItem.Description,
        cartItemID: cartItem.CartItemID,
        price: cartItem.Price,
        amount: cartItem.Amount || 0,
        delete: cartItem.CartItemID,
        total: cartItem.Price,
        maxAdded: cartItem.MaxPerCustomerLeftover,
        maxPerCustomer: cartItem.MaxPerCustomer,
        maxPerCustomerLeftover: cartItem.MaxPerCustomerLeftover,
        compositionID: cartItem.CompositionID,
        invalidCartItemReason: cartItem.InvalidCartItemReason
      })
    })

    cartItems = cartItems.filter(function(obj) {
      return obj.item !== 'Administratiekosten'
    })
  }

  return cartItems
}

async function getBusinessHours({ start, end, warehouseID }) {
  const result = await axiosCreate.get(`/custbusinessdays/BusinessHours`, {
    params: {
      contactID: store.getters.contactID,
      WarehouseID: warehouseID,
      Start: dayjs(start, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      End: dayjs(end, 'DD-MM-YYYY').format('YYYY-MM-DD')
    },
    headers: { authorization: store.getters.token }
  })

  let startHours = []
  let endHours = []

  for (let hourObject of result.data.BusinessHourStart) {
    startHours.push({
      value: hourObject.BusinessHour,
      text: hourObject.BusinessHour
    })
  }

  for (let hourObject of result.data.BusinessHourEnd) {
    endHours.push({
      value: hourObject.BusinessHour,
      text: hourObject.BusinessHour
    })
  }

  const hours = {
    startHours,
    endHours
  }

  return hours
}

async function checkout({ reserverationDetails }) {
  return await axiosCreate.post(
    `cart/${store.getters.contactID}/checkout`,
    {
      CUST_EventDescription: reserverationDetails.eventDescription,
      CUST_EventDate: reserverationDetails.eventStartDate,
      CUST_EventTimePeriod: reserverationDetails.eventStartHour,
      CUST_EventAddress: reserverationDetails.eventAdres,
      CUST_EventExpectedParticipants: reserverationDetails.expectedRise,
      CUST_Files: reserverationDetails.files,
      CUST_Notes: reserverationDetails.extraInfo
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
}

async function checkoutWithFiles({ formData }) {
  return await axiosCreate.post(
    `cart/${store.getters.contactID}/checkout/files`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: store.getters.token
      }
    }
  )
}

async function addToCart({
  warehouseID,
  dateTimeBusinessStart,
  dateTimeBusinessEnd,
  itemID,
  compositionID,
  amount
} = {}) {
  return axiosCreate.post(
    `cart/${store.getters.contactID}/cartitems`,
    [
      {
        WarehouseID: warehouseID,
        DateTimeBusinessStart: dayjs(
          dateTimeBusinessStart,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD'),
        DateTimeBusinessEnd: dayjs(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        ItemID: itemID,
        CompositionID: compositionID,
        Amount: amount
      }
    ],
    { headers: { authorization: store.getters.token } }
  )
}

async function addToCartMultiple({ cartItems } = {}) {
  const formattedCartItems = []

  for (const cartItem of cartItems) {
    formattedCartItems.push({
      WarehouseID: cartItem.warehouseID,
      DateTimeBusinessStart: dayjs(
        cartItem.dateTimeBusinessStart,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD'),
      DateTimeBusinessEnd: dayjs(
        cartItem.dateTimeBusinessEnd,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD'),
      ItemID: cartItem.itemID,
      CompositionID: cartItem.compositionID,
      Amount: cartItem.amount
    })
  }

  return axiosCreate.post(
    `cart/${store.getters.contactID}/cartitems`,
    formattedCartItems,
    { headers: { authorization: store.getters.token } }
  )
}

async function updateCartItem({ cartItemID, amount }) {
  try {
    const result = await axiosCreate.patch(
      `cart/${store.getters.contactID}/cartitems/${cartItemID}`,
      {
        CartItemID: cartItemID,
        Amount: amount
      },
      {
        headers: { authorization: store.getters.token }
      }
    )
    return result
  } catch {
    return false
  }
}

async function updateCart({
  warehouseID,
  businessHourStart,
  businessHourEnd,
  dateTimeBusinessStart,
  dateTimeBusinessEnd
} = {}) {
  const result = await axiosCreate.patch(
    `cart/${store.getters.contactID}`,
    {
      WarehouseID: warehouseID,
      BusinessHourStart: businessHourStart,
      BusinessHourEnd: businessHourEnd,
      DateTimeBusinessStart: dayjs(dateTimeBusinessStart, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      DateTimeBusinessEnd: dayjs(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      )
    },
    {
      headers: { authorization: store.getters.token }
    }
  )

  await getCart()
  return result
}

async function deleteCartItem({ cartItemID }) {
  return axiosCreate.delete(
    `cart/${store.getters.contactID}/cartitems/${cartItemID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )
}

async function cartMutationCheck({
  warehouseID,
  dateTimeBusinessStart,
  dateTimeBusinessEnd
} = {}) {
  const result = await axiosCreate.get(
    `cart/${store.getters.contactID}/invalidCartItems`,
    {
      params: {
        warehouseID,
        dateTimeBusinessStart: dayjs(
          dateTimeBusinessStart,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD'),
        dateTimeBusinessEnd: dayjs(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
      },
      headers: { authorization: store.getters.token }
    }
  )
  return result.data
}

module.exports = {
  getCart,
  updateCartItem,
  addToCart,
  addToCartMultiple,
  deleteCartItem,
  checkout,
  checkoutWithFiles,
  getBusinessHours,
  updateCart,
  cartMutationCheck
}
