const axios = require('axios')

const axiosCreate = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/v1`,
  headers: {
    //Authorization: `Bearer ${store.state.token}`
    //'Cache-Control': 'no-cache',
    //Pragma: 'no-cache',
    //Expires: '0'
  },
  validateStatus: function(status) {
    return status // Resolve only if the status code is less than 500
  }
})

axiosCreate.interceptors.request.use(request => {
  request.headers['accept-language'] = 'en'
  return request
})

module.exports = { axiosCreate }
