<template>
  <div id="app">
    <Header
      v-if="
        this.$route.path != '/' &&
          !this.$route.path.includes('categorie') &&
          !noHeaderFooter
      "
    />
    <header-banner v-else-if="!noHeaderFooter" />
    <content>
      <router-view :key="$route.fullPath" />
    </content>
    <Footer v-if="!noHeaderFooter" />
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import headerBanner from '@/components/header/HeaderBanner'

export default {
  metaInfo: {
    meta: [
      {
        name: `description`,
        content: `Officiële website van de provincie Vlaams-Brabant Uitleendienst.`,
        vmid: 'og:meta-description'
      }
    ]
  },
  components: {
    Header,
    Footer,
    'header-banner': headerBanner
  },
  computed: {
    noHeaderFooter: function() {
      if (this.$route.name === 'openings-days-overview-workload-insight')
        return true
      return false
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/typography';
@import './assets/scss/calendar';
@import './assets/scss/button';
@import './assets/scss/normalize';
@import './assets/scss/cookiebar';

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .item-planner .nav li a {
    padding-left: 18px !important;
    padding-right: 18px !important;
  } /* IE11 */
}
.Cookie {
  transition: none !important;
}
content {
  display: block;
  background: url('/img/Logo-background.svg') fixed;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
  background-position: bottom right;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #efefef !important;
}

.reservation {
  table {
    tr {
      td {
        height: 79px;
        vertical-align: middle;
      }
    }
  }
}

table {
  border: 1px solid #070808;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;

  border-collapse: separate !important;
  border-spacing: 0;

  thead {
    background-color: #070808 !important;
    border-radius: 5px 5px 0 0;
    color: white;
    tr {
      border-radius: 5px !important;
      th {
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  border: none;

  border-collapse: separate;
  border-spacing: 0;

  tr:first-child th,
  tr:first-child td {
    border-top: 1px solid #070808;
  }
  tr td:last-child {
    border-right: 1px solid #070808 !important;
  }
  tr:last-child td {
    border-bottom: 1px solid #070808;
  }
  tr th:first-child,
  tr td:first-child {
    border-left: 1px solid #070808;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 5px;
  }
  tr:first-child th:last-child {
    border-top-right-radius: 5px;
  }
  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }
  background-color: white;
  .form-group {
    margin: 0;
  }
  thead {
    border-radius: none;
  }
  tbody {
    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: white !important;
      vertical-align: baseline;
    }
  }
}
.alert-warning {
  color: black !important;
  background-color: white !important;
  border: 5px solid #d4722f !important;
  border-radius: 5px !important;
  padding: 20px !important;
  span {
    font-family: 'Roboto' !important;
  }
}
.table {
  font-size: 18px;
}
.alert-warning svg {
  color: #d4722f !important;
  margin-right: 20px !important;
}
.alert-info {
  color: black !important;
  background-color: white !important;
  border: 5px solid #015ea5 !important;
  border-radius: 5px !important;
  padding: 20px !important;
}
.alert-info svg {
  color: #015ea5 !important;
  margin-right: 20px !important;
}
.card {
  background-color: white !important;
  border-radius: 5px !important;
  border: none !important;
}
.datepicker-item-disable {
  background-color: #fff !important;
  cursor: not-allowed !important;
  pointer-events: none;
}
.datepicker {
  height: 38px !important;
  input {
    height: 38px !important;
  }
}
.btn {
  padding: 10px !important;
  font-size: 18px !important;
  font-family: 'DecimaPro-bold';
}

.btn.btn-primary:hover {
  background-color: #08436f;
}

.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0) !important;
}
.page-container {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1440px !important;
    padding-left: 129.5px;
    padding-right: 129.5px;
  }
}

@media (max-width: 600px) {
  .container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@import './assets/scss/bootstrap.scss';

.container {
  padding-left: 110px;
  padding-right: 110px;
}
.card-product {
  .product-title {
    font-size: 20px !important;
    a {
      color: #015ea5 !important;
    }
  }
}

.form-control {
  font-family: 'Roboto';
  font-size: 16px !important;
}
.vhd-picker[data-v-0cb4ff92] {
  z-index: 100;
  position: absolute;
  right: 0;
}

@media (min-width: 62em) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 42em) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 32em) {
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.vhd-container {
  width: 100%;
  .vhd-input {
    display: block;
    width: 100%;
    height: add(1.5em, add(0.75rem, 2px, false));
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    min-width: auto !important;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
}

.item-planner {
  h5,
  p {
    font-family: 'Decimapro';
  }
  .nav {
    margin-left: -20px;
    margin-right: -20px;

    li a {
      border-radius: 0px !important;
      background-color: #efefef;
      border: none;
      text-decoration: none;
      color: black;
      height: 80px;
      padding-top: 25px;
      padding-left: 25px;
      padding-right: 25px;
      font-family: 'DecimaPro-bold';
    }
    li a.active {
      background-color: black;
      color: white;
    }
  }
}
.blueimp-gallery {
  background-color: #000000d1 !important;
}
</style>
