const { axiosCreate } = require('./Api')
import axios from 'axios'
const dayjs = require('dayjs')
import ItemExtension from '../objects/ItemExtension.js'
import store from '../state/store'

async function getItems({
  size = 12,
  index = 1,
  categoryID = null,
  search = null,
  AllItemData = true
} = {}) {
  let EmbracedCategoryIDs = null
  if (categoryID) {
    EmbracedCategoryIDs = `[${categoryID}]`
  }

  let result = []

  result = await axiosCreate.get('custitems', {
    params: {
      size,
      index,
      AllItemData,
      EmbracedCategoryIDs,
      Search: search,
      contactID: store.getters.contactID
    },
    headers: { authorization: store.getters.token }
  })

  if (result.data != null && result.data.Collection != null) {
    for (var i = 0; i < result.data.Collection.length; i++) {
      result.data.Collection[i] = new ItemExtension(result.data.Collection[i])
    }
  }

  return result
}

async function getSuggestedItems({ itemID }) {
  const result = await axiosCreate.get(
    `items/${itemID}/itemSuggestions?allitemdata=true`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result.data.Collection
}

async function getItem({
  itemID,
  startDate,
  endDate,
  type = 'items',
  allItemData = true,
  slug = false,
  test
} = {}) {
  let DateTimeBusinessStart = null
  let DateTimeBusinessEnd = null

  if (startDate) {
    DateTimeBusinessStart = dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  if (endDate) {
    DateTimeBusinessEnd = dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  let requestUrl = `${type}/${itemID}/`
  if (slug) {
    requestUrl = `${type}/slug/${itemID}/`
  }

  const result = await axiosCreate.get(requestUrl, {
    params: {
      DateTimeBusinessStart,
      DateTimeBusinessEnd,
      AllItemData: allItemData,
      ContactID: store.getters.contactID,
      test
    },
    headers: { authorization: store.getters.token }
  })

  return new ItemExtension(result.data)
}

async function getPackages() {
  const result = await axios.get(
    'https://vlaams-brabant-shop-service.rentmagic-shop.workers.dev/api/v1/packages',
    {
      // headers: { authorization: store.getters.token }
    }
  )

  return result.data.Collection
}

module.exports = { getItems, getItem, getPackages, getSuggestedItems }
