<template>
  <ul class="vacatures-deputatie">
    <li v-for="childItem in items.slice().splice(0, 5)" :key="childItem.itemID">
      <router-link
        v-if="!packages"
        :to="{
          path: `/catalogus/${parentCategorySlug}/${categorySlug}/artikel/${childItem.Slug}`
        }"
        >{{ childItem.Description }}</router-link
      >

      <router-link
        v-else
        :to="{
          path: `/pakketten/artikel/${childItem.Slug}`
        }"
        >{{ childItem.Description }}</router-link
      >
    </li>
  </ul>
</template>
<script>
import { getItems } from '@/services/ItemService'

export default {
  name: 'ChildItems',
  props: {
    categorySlug: {
      type: String,
      default: null
    },
    parentCategorySlug: {
      type: String,
      default: null
    },
    categoryID: {
      type: [Number, String],
      default: null
    },
    packageItems: {
      type: Array,
      default: null
    },
    packages: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: new Array()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      if (!this.packageItems) {
        const result = await getItems({
          categoryID: this.categoryID,
          slug: true,
          size: 3,
          AllItemData: false
        })

        if (result.data != null) {
          this.items = new Array().concat(result.data.Collection).slice(0, 3)
        }
      } else {
        this.items = this.packageItems
      }
    }
  }
}
</script>
<style lang="scss" scoped>
ul {
  list-style: none;
  padding-left: 23px !important;
  li:before {
    list-style: none;
    content: '>' !important;

    margin-left: -20px;
    margin-right: 10px;
  }
  li {
    line-height: 26px !important;
    font-size: 18px !important;
    font-family: 'Roboto';
    padding-bottom: 25px;
  }
}
</style>
