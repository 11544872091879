import { setTitleFromUrl } from '@/functions/setTitleFromUrl'
setTitleFromUrl(window.location.href)
import { checkGoogleCrawler } from '@/functions/checkGoogleCrawler'
import Vue from 'vue'
import store from './state/store'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import VueJsonLD from 'vue-jsonld'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons/faUserSecret'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleRight'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import { faBox } from '@fortawesome/pro-solid-svg-icons/faBox'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import info from 'vue-material-design-icons/Information.vue'
import check from 'vue-material-design-icons/Check.vue'
import close from 'vue-material-design-icons/Close.vue'
import chevronRight from 'vue-material-design-icons/ChevronRight.vue'
import chevronLeft from 'vue-material-design-icons/ChevronLeft.vue'

//import { ModalPlugin } from 'bootstrap-vue'
//Vue.use(ModalPlugin)

library.add(
  faUserSecret,
  faShoppingCart,
  faFacebookSquare,
  faTwitterSquare,
  faChevronLeft,
  faExternalLink,
  faChevronRight,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faExclamationCircle,
  faSpinner,
  faFileWord,
  faFilePdf,
  faBox
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('info-icon', info)
Vue.component('check-icon', check)
Vue.component('close-icon', close)
Vue.component('chevron-right-icon', chevronRight)
Vue.component('chevron-left-icon', chevronLeft)

Vue.use(VueJsonLD)

Vue.config.productionTip = false

Vue.use(VueMeta)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.VueCalendarLang = function() {
  return {
    daysOfWeek: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    limit: 'Limit reached ({{limit}} items max).',
    loading: 'Laden...',
    minLength: 'Min. Lengte',
    months: [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'September',
      'October',
      'November',
      'December'
    ],
    notSelected: 'Niets geselecteerd',
    required: 'Vereist',
    search: 'Zoeken'
  }
}

// For debugging purposes
window.store = store

if (!checkGoogleCrawler()) {
  var _mtm = (window._mtm = window._mtm || [])
  _mtm.push({
    'mtm.startTime': new Date().getTime(),
    event: 'mtm.Start'
  })
  var d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0]
  g.async = true
  g.src =
    'https://cdn.matomo.cloud/vlaamsbrabant.matomo.cloud/container_GatH4pmp.js'
  s.parentNode.insertBefore(g, s)
}
