<template>
  <router-link to="/">
    <img
      style="width: 149px;height: 63px;"
      alt="Logo Provincie Vlaams-Brabant"
      src="/img/logo.png"
    />
  </router-link>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
img {
  width: 149px;
  height: 63px;
}
</style>
